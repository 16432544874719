/**
 * 工具函数封装
 */
import axios from 'axios'
import qs from 'qs'
import wx from 'weixin-js-sdk'
import Notify from 'quasar/src/plugins/Notify.js';
import Clipboard from 'clipboard'
import i18n from './../language/index'
const $t = i18n.global.t

/**
 *
 * @param {String} domid 元素id
 */
export const copyHandle = (domid) => {
  const clipboard = new Clipboard(domid)
  clipboard.on('success', (e) => {
    Notify.create($t('common.copy_success'))
    // 释放内存
    clipboard.destroy()
  })
  clipboard.on('error', (e) => {
    // 不支持复制
    Notify.create($t('common.cant_copy'))
    // 释放内存
    clipboard.destroy()
  })
}

/**
 *
 * @returns 判断是否微信环境
 */
export const isWeChar = () => {
  // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  var ua = window.navigator.userAgent.toLowerCase()
  // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
  // eslint-disable-next-line eqeqeq
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}
/**
 * 密码生成器
 * @param {Number} length  密码长度
 * @param {Array} passwordArray  生成规则
 */
export const randPassword = (length, passwordArray) => {
  length = length || 6
  // 密码串----默认 大写字母 小写字母 数字
  passwordArray = passwordArray || ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '1234567890']
  let password = ''
  // 随机生成开始字符串
  const startIndex = parseInt(Math.random() * (length))
  const randIndex = []
  for (let i = 0; i < length; i++) {
    // 创建数组，用于取随机位置  [0,1,2,3,4,5,....]
    randIndex.push(i)
  }
  for (let i = 0; i < length; i++) {
    // 根据随机数组生成随机位置
    const r = parseInt(Math.random() * (randIndex.length))
    const num = randIndex[r] + startIndex
    // 根据随机值取余数
    const randRemainder = num % passwordArray.length
    // 当前密码串【大写字母，小写字母，数字等】
    const currentPassword = passwordArray[randRemainder]
    // 根据当前密码串长度取随机数
    const index = parseInt(Math.random() * (currentPassword.length))
    // 获取随机字符串
    const str = currentPassword.substr(index, 1)
    // 删除随机数组中已经使用的值
    randIndex.splice(r, 1)
    password += str
  }
  return password
}
/**
 * 设置微信分享
 */
export const weixinShouQuan = (share1 = {
  title: 'ANNILUX 商城', // 分享标题
  desc: 'ANNILUX 商城', // 分享描述
  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  imgUrl: window.location.origin + '/img/shareimg.jpg', // 分享图标
  success: function () {
    console.log('分享成功')
  },
  cancel: function () {
    console.log('取消分享')
  }
}, share2 = {
  title: 'ANNILUX 商城', // 分享标题
  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  imgUrl: window.location.origin + '/img/shareimg.jpg', // 分享图标
  success: function () {
    console.log('分享成功')
  },
  cancel: function () {
    console.log('取消分享')
  }
}) => {
  const _wx = {
    // 分享朋友
    share1,
    // 分享朋友圈
    share2
  }
  const url = (document.location.protocol === 'https:' ? 'https://' : 'http://') + window.location.host + '/common/joy.aspx'
  axios.post(url, qs.stringify({
    urls: window.location.href.split('#')[0]
  })).then(res => {
    wx.config({
      debug: false, // 开启调试模式
      appId: res.data.appId, // 必填，公众号的唯一标识
      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      signature: res.data.signature, // 必填，签名，见附录1
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData'
      ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    })
    wx.ready(function () {
      // 分享给朋友
      wx.updateAppMessageShareData(_wx.share1)
      // 分享到朋友圈
      wx.updateTimelineShareData(_wx.share2)
    })
    wx.error(function (res) {
    })
  })
}

/**
 * 判断是否移动端
 * @returns  true 和 false
 */
export const isMobile = () => {
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    return true // 手机端
  } else {
    return false // alert('PC端')
  }
}

/**
 * PC端、手机端跳转页面
 */
export const switchLink = () => {
  let isReturn = true
  if (document.body.clientWidth < 800 && isReturn) {
    const url = window.location.origin + '/mobile' + window.location.pathname + window.location.search
    window.location.href = url
  }
  window.onresize = () => {
    return (() => {
      if (document.body.clientWidth < 800 && isReturn) {
        isReturn = false
        const url = window.location.origin + '/mobile' + window.location.pathname + window.location.search
        window.location.href = url
      }
    })()
  }
}
