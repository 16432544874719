import myIndex from '@/views/My/Index.vue'

const routes = [{
  path: '/my', // 个人中心
  component: myIndex,
  redirect: '/my/info',
  meta: {
    islogin: true
  },
  children: [
    {
      path: 'info',
      component: () => import('@/views/My/Info.vue'),
      meta: {
        navid: 0
      }
    },
    {
      path: '',
      component: () => import('@/views/My/Member/Index.vue'),
      redirect: '/my/balance',
      children: [
        {
          path: 'balance',
          component: () => import('@/views/My/Member/Balance.vue')
        },
        {
          path: 'points',
          component: () => import('@/views/My/Member/Integral.vue')
        },
        {
          path: 'prize',
          component: () => import('@/views/My/Member/Prize.vue')
        },
        {
          path: 'exchange',
          component: () => import('@/views/My/Member/Exchange/Exchange.vue')
        },
        {
          path: 'exchangeList',
          component: () =>
            import('@/views/My/Member/Exchange/ExchangeList.vue')
        },
        {
          path: 'exchangeDetail',
          component: () =>
            import('@/views/My/Member/Exchange/ExchangeDetail.vue')
        },
        {
          path: 'inviteRecord', // 邀请记录
          component: () => import('@/views/My/Member/InviteRecord.vue'),
          meta: {
            navid: 6
          }
        }
      ],
      meta: {
        navid: 3
      }
    },
    {
      path: 'address',
      component: () => import('@/views/My/Address/address.vue'),
      meta: {
        navid: 4
      }
    },
    {
      path: 'addressEdit',
      component: () => import('@/views/My/Address/addressEdit.vue'),
      meta: {
        navid: 4
      }
    },
    {
      path: 'order',
      component: () => import('@/views/My/Order.vue'),
      meta: {
        navid: 1
      }
    },
    {
      path: 'returnGoodsList', // 退换货品
      component: () => import('@/views/My/ReturnGoodsList.vue'),
      meta: {
        navid: 2
      }
    },
    {
      path: 'ReturnGoods', // 退换记录
      component: () => import('@/views/My/ReturnGoods.vue'),
      meta: {
        navid: 2
      }
    },
    {
      path: 'salesRecord', // 销售记录
      component: () => import('@/views/My/SalesRecord.vue'),
      meta: {
        navid: 5
      }
    },
    {
      path: 'forget', // 忘记密码
      component: () => import('@/views/My/changeInfo/Forget.vue'),
      meta: {
        islogin: false
      }
    },
    {
      path: 'phone',
      component: () => import('@/views/My/changeInfo/phone.vue')
    },
    {
      path: 'email',
      component: () => import('@/views/My/changeInfo/email.vue')
    }
  ]
}]

export default routes
