import { goodslist } from '@/api/goods'
import _ from 'lodash'

const data = {
  htype: '', // 人群id
  typeid: 1, // 商城id
  pageindex: 1, // 页码
  pagesize: 20, // 页数
  generalattributeid: [], // 品牌id
  parentcategoryid: '', // 大分类id
  categoryid: '', // 小分类id
  currencycommodityname: '', // 搜索商品名称
  order: 0, // 排序
  Price_f: '', // 价格区间低
  Price_a: '', // 价格区间高
  isdiscount: 0
}

// 重置数据
const initParams = callback => {
  return (state, payload) => {
    state.list = []
    state.params.Price_f = ''
    state.params.Price_a = ''
    state.params.pageindex = 1
    state.isUpdateSection = true
    callback(state, payload)
  }
}

const goodsModule = {
  state: {
    originalParams: _.cloneDeep(data),
    params: _.cloneDeep(data),
    list: [],
    totalCount: 0,
    finished: false,
    loading: false,
    cateSelectArr: [],
    brandSelectArr: [],
    section: {
      lowerPrice: 1,
      topPrice: 1
    },
    isUpdateSection: true,
    isOnlyBrand: false,
    queryInfo: {},
    isUpdataParams: false, // 是否启动更新
    brandName: '',
    categoryname: {},
  },
  mutations: {
    setDiscount: initParams((state, value) => {
      state.params.isdiscount = value
    }),
    modeChange: initParams((state, e) => {
      state.params.order = e.value
    }),
    pageChange(state) {
      state.list = []
    },
    rangeChange(state, e) {
      state.list = []
      state.params.pageindex = 1
      state.params.Price_f = e.min
      state.params.Price_a = e.max
    },
    rangeBlur(state, e) {
      state.list = []
      state.params.pageindex = 1
      state.params.Price_f = e.low
      state.params.Price_a = e.top
    },
    setCateSub: initParams((state, e) => {
      state.cateSelectArr = []
      state.categoryname = {
        title: e.title,
        titleen: e.titleen
      }
      state.params.parentcategoryid = e.guid
      state.params.categoryid = ''
    }),
    getCateSub: initParams((state, cateSub) => {
      state.cateSelectArr = cateSub.filter(o => {
        return o.isSelect
      })
      const arr = state.cateSelectArr.map(o => {
        return o.guid
      })
      state.params.categoryid = arr.join(',')
    }),
    restoreCateSelect(state, cateSub) {
      state.cateSelectArr = cateSub.filter(o => {
        return o.isSelect
      })
    },
    getBrand: initParams((state, brandList) => {
      state.brandSelectArr = brandList.filter(o => {
        return o.isSelect
      })
      const arr = state.brandSelectArr.map(o => {
        return o.guid
      })
      state.params.generalattributeid = JSON.stringify([{ 品牌: arr }])
    }),
    restoreBrandSelect(state, brandList) {
      state.brandSelectArr = brandList.filter(o => {
        return o.isSelect
      })
    },
    crowdChange: initParams((state, id) => {
      state.cateSelectArr = []
      state.brandSelectArr = []
      state.params = _.cloneDeep(data)
      if (id) {
        state.params.htype = id
      } else {
        state.params.htype = ''
      }
    }),
    removeCateSelect: initParams((state, index) => {
      state.cateSelectArr.splice(index, 1)
      const arr = state.cateSelectArr.map(o => {
        return o.guid
      })
      state.params.categoryid = arr.join(',')
    }),
    removeBrandSelect: initParams((state, index) => {
      state.brandSelectArr.splice(index, 1)
      const arr = state.brandSelectArr.map(o => {
        return o.guid
      })
      state.params.generalattributeid = JSON.stringify([{ 品牌: arr }])
    }),
    removeAllSelect: initParams(state => {
      state.cateSelectArr = []
      state.params.categoryid = ''
      if (state.isOnlyBrand) {
        return
      }
      state.brandSelectArr = []
      state.params.generalattributeid = ''
    }),
    AllCate: initParams(state => {
      state.params.parentcategoryid = ''
      state.cateSelectArr = []
      state.categoryname = {}
      state.params.categoryid = ''
    }),
    resetHandle: initParams((state, id) => {
      state.isOnlyBrand = false
      state.brandName = ''
      state.categoryname = {}
      state.cateSelectArr = []
      state.brandSelectArr = []
      state.params = _.cloneDeep(data)
      state.params.htype = id
      state.params.order = 0
    }),
    restoreHandle: initParams((state, param) => {
      state.cateSelectArr = []
      state.brandSelectArr = []
      state.params = _.cloneDeep(param)
    }),
    updataHandle: initParams((state, param) => {
      state.isUpdataParams = true
      state.cateSelectArr = []
      state.brandSelectArr = []
      state.params = _.cloneDeep(param)
    }),
    setCategoryname(state, obj) {
      state.categoryname = obj
    },
    setBrandName(state, title) {
      state.brandName = title
    },
    setOnlyBrand(state, bool) {
      state.brandName = ''
      state.categoryname = {}
      state.isOnlyBrand = bool
    }
  },
  actions: {
    getList: async context => {
      const { state } = context
      state.isUpdataParams = false
      if (state.loading) return
      state.loading = true
      const res = await goodslist({
        ...state.params
      })
      if (res.status === 1) {
        if (res.commodity.length) {
          res.commodity.forEach(item => {
            item.size = item.size.split(',').join(' / ')
          })
        }
        state.list.push(...res.commodity)
        if (state.params.pageindex === 1) {
          state.totalCount = Math.ceil(res.totalCount / state.params.pagesize)
          // 是否更新价格区间
          if (state.isUpdateSection) {
            if (res.lowerPrice && res.topPrice) {
              state.section.lowerPrice = parseInt(res.lowerPrice)
              state.section.topPrice = parseInt(res.topPrice)
            }
            state.isUpdateSection = false
          }
        }
        if (res.commodity.length < state.params.pagesize) {
          state.finished = true
        } else {
          state.finished = false
        }
        // 更新路由参数
        state.queryInfo = _.cloneDeep(state.params)
      }
      state.loading = false
    }
  },
  getters: {

  }
}

export default goodsModule
