
import Dialog from 'quasar/src/plugins/Dialog.js';
import i18n from '../language'

const errorHandle = (err) => {
  if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
    Dialog.create({
      title: i18n.global.locale === 'en' ? 'TIP' : '提示',
      message: i18n.global.locale === 'en' ? 'The request timed out. Please check the network' : '请求超时，请检查网络'
    })
  } else if (
    err.message !== undefined &&
    err.message.indexOf('Network Error') !== -1
  ) {
    Dialog.create({
      title: i18n.global.locale === 'en' ? 'TIP' : '提示',
      message: i18n.global.locale === 'en' ? 'Network error, please check the network' : '网络错误，请检查网络'
    })
  }
}

export default errorHandle
