module.exports = {
  // 公共
  common: {
    men: '男士',
    women: '女士',
    kids: '儿童',
    sale: '折扣',
    boy: '男孩',
    girl: '女孩',
    all: '全部',
    collections: '系列',
    brands: '品牌',
    categorys: '类别',
    view_all: '查看全部',
    back: '返回',
    submit: '提交',
    out: '登出',
    no_search: '没有搜索结果',
    c_shopping: '继续购物',
    tip: '提示',
    copy_success: '复制成功',
    cant_copy: '该浏览器不支持复制，请手动复制',
    go_shopping: '去逛逛',
    add_wish_success: '添加心愿成功!',
    remove_wish_success: '取消心愿成功!',
    confirm_delete: '确定删除？',
    confirm_out: '确定退出？',
    exchange_success: '兑换成功！',
    reg_receive: '注册领取',
    no_restrictions: '无门槛',
    get_now: '立即领取',
    no_goods: '该商品暂未库存',
    cn_site: '中国站',
    uk_site: '英国站',
    click_switch: '点击切换'
  },
  // 品牌a-z
  brands_az: {
    like_brands: '喜欢的品牌',
    search_brand: '搜索品牌',
    no_find: '未找到任何结果'
  },
  // 头部菜单
  header_menu: {
    language: 'CN',
    fees: '包含关税',
    users: '用户专区',
    top_brands: 'TOP品牌精选',
    category: '根据类别选择',
    brand_a_z: '品牌A-Z',
    view_brans_all: '查看全部品牌',
    popular: '人气品牌榜单',
    integral: '积分',
    member: '会员积分',
    search_sale: '折扣',
    search_goods: '商品',
    search_brand: '品牌',
    search_cate: '分类'
  },
  // 底部菜单
  footer_menu: {
    your_email: '您的邮箱',
    about_us: '关于我们',
    service_support: '服务支持',
    users: '用户专区',
    hours24: '营业时间24小时',
    classify: '商品分类',
    subscribed: '订阅ANNILUX新闻邮箱',
    enter_email: '请填写邮箱地址',
    email_err: '邮箱格式错误',
    subscription_succeeded: '订阅成功'
  },
  // 登录或注册
  login_mod: {
    login_t: '登录',
    reg_t: '注册',
    phone_t: '手机号码',
    phone_format: '手机格式错误',
    email_t: '邮箱地址',
    email_format: '邮箱地址格式错误',
    pwd_t: '密码',
    keep_signed: '保持登录状态',
    forgot_pwd: '忘记密码?',
    ro_t: '或使用',
    go_login: '去登录',
    name: '姓名',
    name_input: '请填写姓名',
    verification_code: '验证码',
    send_verification_code: '发送验证码',
    enter_phone: '输入手机号',
    enter_email: '输入邮箱地址',
    enter_pwd: '输入密码',
    enter_verification_code: '请输入验证码',
    invitecode: '邀请码',
    re_pwd: '确认密码',
    reset_pwd: '重置密码',
    no_one_pwd: '密码不一致',
    change_pwd: '修改密码',
    p_out: '请先退出登录',
    old_phone_t: '原手机号码',
    new_phone_t: '新手机号码',
    old_phone_t_err: '原手机号不正确',
    old_email_t_err: '原邮箱地址不正确',
    submit: '提交',
    reg_success: '注册成功，将自动登录',
    pwd_format: '至少8-16个字符，1个小写字母和1个数字'
  },
  // 个人中心导航
  navList: {
    account: '我的账户',
    orders: '我的订单',
    return: '退换记录',
    vip: '会员中心',
    address: '收货地址管理',
    sale: '销售记录',
    invite: '邀请记录'
  },
  account: {
    wechart: '微信昵称',
    invitecode: '邀请码',
    name: '姓名',
    gender: '性别',
    birthday: '生日',
    pwd: '密码',
    phone: '手机号码',
    email: '邮箱地址',
    save: '保存更改',
    male: '男',
    female: '女',
    close: '关闭',
    bind_phone: '绑定手机',
    change_phone: '更改手机',
    verification_code: '验证码',
    send_verification_code: '发送验证码',
    set_email: '设置邮箱'
  },
  orders: {
    change: '变更',
    all: '全部',
    pending: '待发货',
    shipped: '待收货',
    completed: '已完成',
    no_order: '暂无订单',
    search_order: '搜索订单',
    date: '日期',
    order_number: '订单号',
    shipping_address: '配送地址',
    voucher: '优惠券',
    s_pay: '实付款',
    y_pay: '应付款',
    b_pay: '余额付款',
    refund: '退款',
    b_refund: '余额退款',
    look_wl: '查看物流',
    look_xj: '查看细节',
    apply_retrun: '申请退换',
    name: '联系人：',
    phone: '联系方式：',
    email: '邮箱地址：',
    address: '地址：',
    post_code: '邮编：',
    status: '状态',
    product: '产品',
    price: '单价',
    num: '数量',
    total: '总计',
    color: '颜色：',
    size: '尺码：',
    g_code: '货号：',
    confirm_receipt: '确定收货',
    total_discount: '总优惠：',
    before_exchange: '换货前商品',
    look_before_exchange: '查看换货前商品',
    goods_received_successfully: '收货成功',
    not_used: '未使用',
    reason: '原因',
    normal_goods: '正常商品',
    buy_user: '购买用户',
    select_return: '选择退换货品',
    return_fail: '退货未通过',
    return_fail2: '退款未通过',
    exchange_fail: '换货未通过',
    return_examine: '退货审核中',
    return_examine2: '退款审核中',
    exchange_examine: '换货审核中',
    exchange_complete: '换货成功',
    return_complete: '退货成功',
    return_complete2: '退款成功',
    wait_customer_service: '请耐心等待客服审核',
    exchange_goods: '换货商品',
    color2: '颜色',
    size2: '尺码',
    successful_trade: '交易成功',
    p_select_color_size: '请选择退换颜色和尺寸',
    select_confirm_receipt: '请选择是否收到货',
    delivered: '因商家国际物流已发货，需要签收后再申请退换货',
    contact_your: '我们会尽快派人和你联系，感谢你的支持和理解',
    return_received: '已收到货',
    return_not_received: '未收到货',
    return_button: '退换商品',
    return_mode: '退换方式',
    return_reason: '退换方式',
    return_title: '退换',
    product_title: '产品',
    is_received: '是否收到货',
    full_refund: '全额退款',
    change_color_size: '更换尺寸 / 颜色',
    wrong_size: '尺寸错误',
    wrong_color: '颜色错误',
    dislike: '不喜欢',
    defects: '不合格',
    other: '其他',
    downInvoice: '下载发票'
  },
  vip: {
    order_date: '订单日期：',
    posting_date: '入账日期：',
    abalance: '余额',
    wbalance: '待入余额',
    my_points: '我的积分',
    my_balance: '我的余额',
    my_rewards: '我的奖品',
    points_changes: '积分兑换',
    questions: '常见问题',
    active: '活动',
    date: '日期',
    points: '积分',
    status: '状态',
    tip1: '在此您可以查看所赚取的积分来源明细单。\n 通过消费赚取的积分需确认退货期期间无法退货，自商品签收日期起35天后入到您的账户中。\n积分到期日为月份的最后一天。',
    rank: '等级',
    your_rewards: '您的奖品',
    rewards: '奖品',
    rewards_code: '奖品码',
    effective_time: '有效时间',
    shop_order: '订单：',
    exchange: '兑换',
    not_available: '已兑完',
    introduce: '细节',
    copy: '复制',
    used: '已使用',
    no_code: '无奖品码',
    look_use_prize: '查看已使用的奖品',
    hide: '收起',
    no_prize: '暂无奖品',
    redeem_points: '兑换积分',
    redeem_points_detail: '使用您的积分兑换独家奖品。奢华体验或用于ANNILUX.COM的订单。',
    credit: '礼券',
    use_credit: '使用积分兑换折礼券',
    see_option: '查看选项',
    privilege_card: '贵宾卡',
    use_privilege_card: '使用积分兑换贵宾卡',
    free_shipping: '免费配送',
    use_free_shipping: '使用积分兑换免费配送',
    points_ex: '积分兑换',
    discover_more: '搜索更多',
    discount: '折',
    prize_detail: '您可在此查看从注册至今所获得的所有奖品',
    expiring_points: '快到期积分',
    exchange_succeeded: '兑换成功',
    waiting_points: '待入积分',
    available_points: '可用积分',
    about_expire: '即将过期',
    no_goods: '本站点暂无该商品'
  },
  address: {
    my_address: '我的收货地址',
    new_address: '新增收货地址',
    edit: '编辑',
    delet: '删除',
    name: '收货人：',
    city: '所在地区：',
    contact: '联系方式：',
    post: '邮政编码：',
    email_address: '邮箱地址：',
    ship_to: '当前配送至：',
    address_info: '地址信息：',
    detail_address: '详细地址：',
    phone: '手机号码：',
    set_default: '设为默认地址',
    save: '保存',
    back_order: '返回订单',
    add_tip: '请添加收货地址',
    show_all: '展开全部',
    hide_all: '收起全部',
    default_address: '默认地址',
    select_address: '选中地址',
    enter_address: '请输入详细地址，如道路、门牌号、小区、楼栋号、单元等信息',
    enter_name: '请输入收货人姓名',
    enter_email: '请填写邮箱地址',
    enter_region: '选择省市区',
    enter_postcode: '请填写邮编',
    enter_phone: '请填写手机号',
    phone_err: '手机格式错误',
    email_err: '邮箱格式错误',
    add: '新增',
    change_success: '修改成功',
    distributionCN: '中国',
    distributionAB: '海外',
    country: '国家',
    citys: '城市',
    enter_country: '请输入国家',
    enter_citys: '请输入城市'
  },
  invite: {
    invite_users: '邀请用户',
    time: '注册时间'
  },
  wish: {
    wish_title: '心愿清单',
    add_cart: '放入我的购物车',
    no_wish: '暂无心愿清单',
    color: '颜色',
    size: '尺寸',
    price: '价格：',
    ok: '确定',
    cancel: '取消',
    withdrawn: '已下架',
    no_stock: '已售罄',
    confirm_delete: '是否移除该商品？',
    add_success: '加入购物车成功'
  },
  cart: {
    Total_Product: '商品总计',
    Switch_Sites: '切换站点',
    isUseBalance: '余额：',
    cart_title: '购物车',
    sum: '共计数量',
    total: '总计',
    remove: '删除',
    select: '全选',
    buy: '购买',
    goods_color: '颜色',
    goods_code: '货号',
    goods_size: '尺寸',
    goods_price: '价格',
    check_title: '确定订单',
    play: '提交订单',
    c_play: '确定支付',
    num: '件',
    nums: '数量',
    order_number: '订单号：',
    no_select: '请选择商品',
    c_delete: '确定删除商品？',
    show_all: '展开全部',
    hide_all: '收齐全部',
    withdrawn: '商品已下架',
    no_stock: '暂无库存',
    get: '可获得',
    point: '积分',
    confirm: '确定',
    enter_code: '请填写奖品码',
    use_success: '使用成功',
    enter_address: '请填写地址',
    no_goods: '购物车还没有商品',
    pay_tip: '正在使用即时到账交易，请即时付款！',
    reduction: '减免',
    discount_code: '优惠代码',
    credit: '礼券',
    dis_j: '折扣卷',
    please_address: '请添加地址',
    coupontitle: '配送费用',
    coupontitles: '优惠配送费用',
    paid: '已付款',
    account_pay: '应付款',
    s_pay_price: '实付金额',
    y_pay_price: '应付金额',
    Use_balance: '使用余额'
  },
  goods_list: {
    mode: '查看方式',
    mode_all: '全部',
    new: '最新上架',
    low_price: '按价格升序',
    high_price: '按价格降序',
    all: '全部',
    price: '价格区间',
    cate_all: '商品总类',
    cate_sub: '类别',
    look_all: '查看全部',
    brands: '品牌',
    search: '搜索',
    more: '加载更多商品',
    all_remove: '全部移除',
    tip_low: '不能低于最低价',
    tip_top: '不能高于最高价'
  },
  goods: {
    color: '颜色',
    size: '尺寸',
    add_cart: '加入我的购物车',
    add_wish: '加入心愿清单',
    copy_link: '复制销售链接',
    recommend_goods: '推荐商品',
    recommend_brands: '推荐品牌',
    code: '编号：',
    product_detail: '产品详情',
    share_to: '分享到',
    brand: '品牌',
    goods: '商品',
    price: '价格',
    add_cart_suss: '您已将本商品加入购物车',
    go_shopping: '继续逛',
    go_pay: '去结算',
    select_color: '请选择颜色',
    select_color_size: '请选择颜色尺码',
    wechat: '微信',
    weibo: '微博',
    get: '可获得',
    withdrawn: '商品已下架',
    no_stock: '暂无库存',
    points: '积分'
  },
  payment_info: {
    pay_success: '您已付款成功！',
    order_detail: '订单详情',
    back_cart: '返回购物车',
    order_info: '订单信息',
    order_id: '订单编号：',
    pay_mode: '付款方式：',
    create_time: '创建时间：',
    pay_time: '成交时间：',
    shop_address: '收货地址：',
    name: '联系人：',
    phone: '手机号：',
    email: '邮箱地址：'
  }
}
