<template>
  <div v-if="isnav" class="punav">
    <div class="punav-cont">
      <div class="title">
        <span @click="$router.push('/')">ANNILUX</span>
      </div>
      <header-menu></header-menu>
      <div v-if="navInfo.length" class="search-box" @mouseenter="searchEndter" @mouseleave="searchLeave">
        <q-input dense v-model="searchVal" @update:model-value="searchValChange" @keyup.enter="searchClick">
          <template v-slot:prepend>
            <q-icon name="search" @click="searchClick" class="cursor-pointer" />
          </template>
          <template v-slot:append>
            <q-icon name="close" @click="searchVal = ''" class="cursor-pointer" />
          </template>
        </q-input>
        <div class="search-cont" v-show="searchValShow">
          <div class="search-top">
            <div style="width: 60px; margin: 0 auto">
              <q-select dense borderless v-model="searchPageType" :options="options" option-label="title" option-value="id" @update:model-value="selectType" />
            </div>
            <div class="search-disc-check">
              <q-checkbox size="xs" v-model="isSearchDiscount" :label="$t('header_menu.search_sale')" @update:model-value="SearchDiscountChange" />
            </div>
          </div>
          <q-tabs dense v-model="searchType">
            <q-tab name="goods" :label="$t('header_menu.search_goods')" />
            <q-tab name="cate" :label="$t('header_menu.search_cate')" />
            <q-tab name="brand" :label="$t('header_menu.search_brand')" />
          </q-tabs>
          <q-scroll-area style="height: 500px">
            <q-tab-panels v-model="searchType" animated>
              <q-tab-panel name="goods">
                <q-list>
                  <q-item dense clickable v-for="(item, index) in goodsList" :key="index" @click="searchGolink('商品', item)">
                    <q-item-section>
                      <span class="search-text">{{
                        $i18n.locale == "en" ? item.titleen : item.title
                      }}</span>
                    </q-item-section>
                  </q-item>
                  <span class="search-text" v-if="!goodsList.length">{{
                    $t("common.no_search")
                  }}</span>
                  <q-inner-loading :showing="searchLoading"></q-inner-loading>
                </q-list>
              </q-tab-panel>
              <q-tab-panel name="cate">
                <q-list>
                  <q-item dense clickable v-ripple v-for="(item, index) in cateList" :key="index" @click="searchGolink('分类搜索', item)">
                    <q-item-section>
                      <span class="search-text">{{
                        $i18n.locale == "en" ? item.titleen : item.title
                      }}</span>
                    </q-item-section>
                  </q-item>
                  <span class="search-text" v-if="!cateList.length">{{
                    $t("common.no_search")
                  }}</span>
                </q-list>
              </q-tab-panel>
              <q-tab-panel name="brand">
                <q-list>
                  <q-item dense clickable v-ripple v-for="(item, index) in brandList" :key="index" @click="searchGolink('品牌', item)">
                    <q-item-section>
                      <span class="search-text">{{ item.title }}</span>
                    </q-item-section>
                  </q-item>
                  <span class="search-text" v-if="!brandList.length">{{
                    $t("common.no_search")
                  }}</span>
                  <q-inner-loading :showing="searchLoading2"></q-inner-loading>
                </q-list>
              </q-tab-panel>
            </q-tab-panels>
          </q-scroll-area>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loginnav pucontainer">
    <div class="title" @click="$router.push('/')">ANNILUX</div>
  </div>
</template>

<script>
import { goodslist } from '@/api/goods'
import HeaderMenu from './HeaderMenu.vue'
import _ from 'lodash'
import store from '../../store'
import { currency2 } from '@/api/common'

export default {
  props: {
    isnav: {
      type: Boolean,
      default: true
    }
  },
  components: {
    HeaderMenu
  },
  data () {
    return {
      options: [
        { id: '1', title: this.$t('common.men') },
        { id: '2', title: this.$t('common.women') },
        { id: '3', title: this.$t('common.boy') },
        { id: '4', title: this.$t('common.girl') }
      ],
      searchVal: '',
      searchType: 'goods',
      searchPageType: { id: '1', title: this.$t('common.men') },
      goodsList: [],
      cateList: [],
      brandList: [],
      searchLoading: false,
      searchLoading2: false,
      searchTimer: null,
      mouseTimer: null,
      searchShowTimer: null,
      searchValShow: false,
      brandData: [],
      isFirst: false,
      isSearchDiscount: false
    }
  },
  computed: {
    navInfo () {
      return store.state.navItem
    },
    pageType () {
      return store.state.pageType
    }
  },
  watch: {
    pageType: {
      handler (val) {
        // 改变搜索类型
        const item = this.options.find((o) => {
          return o.id === store.state.pageType.id
        })
        this.searchPageType = item
        this.searchValChange()
      },
      immediate: true
    }
  },
  methods: {
    async getBrandApi () {
      const res = await currency2({
        id: store.state.pageType.id
      })
      if (res.status === 1) {
        this.brandData = _.get(res, 'item[0].generalattribute[0].item') || []
        if (!this.isFirst) {
          this.isFirst = true
        }
      }
    },
    // 搜索商品
    async Search () {
      store.commit('setIsStopLoading') // 停止加载loading动画
      this.searchLoading = true
      const obj = {
        htype: this.searchPageType.id,
        pageindex: 1,
        pagesize: 50,
        typeid: 1,
        currencycommodityname: this.searchVal,
        isdiscount: this.isSearchDiscount ? 1 : 0,
        channel: this.$i18n.locale === 'en' ? 2 : 1
      }
      const res = await goodslist(obj)
      if (res.status === 1) {
        this.goodsList = res.commodity
      }
      this.searchLoading = false
    },
    // 获取分类
    getCateList () {
      const _this = this
      let arr = []
      const res = this.navInfo
      const list = res.find((val) => {
        return val.id === this.searchPageType.id
      })
      const check = (i) => {
        if (_this.$i18n.locale === 'en') {
          return i.titleen.indexOf(_this.searchVal) !== -1
        } else {
          return i.title.indexOf(_this.searchVal) !== -1
        }
      }
      list.categorys.forEach((i) => {
        if (check(i)) {
          i.item.forEach((item) => {
            item.parentcategoryid = i.guid
            item.categoryname = i.title
          })
          arr = JSON.parse(JSON.stringify(i.item))
        } else {
          i.item.forEach((j) => {
            if (check(j)) {
              j.parentcategoryid = i.guid
              j.categoryname = i.title
              arr.push(j)
            }
          })
        }
      })
      this.cateList = arr
    },
    // 获取品牌
    getBrand () {
      const arr = []
      const brandArr = this.brandData
      brandArr.forEach((item) => {
        if (
          item.title.toLowerCase().indexOf(this.searchVal.toLowerCase()) >= 0
        ) {
          arr.push(item)
        }
      })
      this.brandList = arr
    },
    searchEndter () {
      clearTimeout(this.searchShowTimer)
    },
    searchLeave () {
      this.searchShowTimer = setTimeout(() => {
        this.searchValShow = false
        clearTimeout(this.searchShowTimer)
      }, 1500)
    },
    // 搜索框跳转
    searchGolink (type, item) {
      const params = _.cloneDeep(store.state.goodsModule.originalParams)
      params.htype = this.searchPageType.id
      if (type === '商品') {
        this.$router.push('/goods?guid=' + item.guid)
        return
      } else if (type === '分类搜索') {
        params.parentcategoryid = item.parentcategoryid
        params.categoryid = item.guid
      } else if (type === '品牌') {
        params.generalattributeid = JSON.stringify([{ 品牌: [item.guid] }])
      }
      if (this.$route.path === '/goodslist') {
        store.commit('updataHandle', params)
      } else {
        const query = JSON.stringify(params)
        this.$router.push({
          path: '/goodslist',
          query: {
            params: encodeURI(query),
            discount: this.isSearchDiscount ? 1 : null
          }
        })
      }
    },
    // 搜索框变化
    searchValChange () {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.goodsList = []
        if (this.searchVal !== '') {
          this.Search()
          this.getCateList()
          this.getBrand()
          this.searchValShow = true
        } else {
          this.cateList = []
          this.brandList = []
        }
      }, 1000)
    },
    searchClick () {
      if (this.searchVal === '') {
        return
      }
      const params = _.cloneDeep(store.state.goodsModule.originalParams)
      params.htype = this.searchPageType.id
      params.currencycommodityname = this.searchVal
      if (this.$route.path === '/goodslist') {
        store.commit('updataHandle', params)
      } else {
        const query = JSON.stringify(params)
        this.$router.push({
          path: '/goodslist',
          query: {
            params: encodeURI(query),
            discount: this.isSearchDiscount ? 1 : null
          }
        })
      }
    },
    // 搜索框男女变化
    async selectType () {
      this.searchLoading2 = true
      const res = await currency2({
        id: this.searchPageType.id
      })
      if (res.status === 1) {
        this.brandData = res.item[0].generalattribute[0].item
        this.searchValChange()
      }
      this.searchLoading2 = false
    },
    SearchDiscountChange () {
      this.searchValChange()
    }
  }
}
</script>

<style lang="scss" scoped>
.punav {
  text-align: center;
  border-bottom: 1px solid #ccc;
  .punav-cont {
    position: relative;
    max-width: 1500px;
    margin: 0 auto;
    .title {
      font-size: 30px;
      color: #000;
      font-weight: bold;
      margin: 5px 0;
      text-align: center;
      span {
        cursor: pointer;
      }
    }
  }
  .search-box {
    position: absolute;
    right: 15px;
    bottom: 5px;
    z-index: 99;

    .search-cont {
      min-width: 230px;
      position: absolute;
      top: 40px;
      right: 0;
      background: #fff;
      color: #000;
      border: 1px solid rgba(0, 0, 0, 0.12);
      .search-top {
        position: relative;
        .search-disc-check {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .search-text {
        font-size: 12px;
        color: #333;
      }
    }
  }
}
.loginnav {
  border-bottom: 1px solid #ccc;
  .title {
    margin: 15px 0;
    font-size: 26px;
    color: #000;
    text-align: center;
    font-weight: bold;
  }
}
.q-item__label {
  text-align: center;
}
.q-tab-panel {
  padding: 0;
}
</style>
