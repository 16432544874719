<template>
  <div class="container">
    <div class="footerCon pucontainer">
      <div class="email">
        <q-input :maxlength="100" lazy-rules="ondemand" hide-bottom-space dense outlined v-model="email"
          bg-color="white" color="white" :placeholder="$t('footer_menu.your_email')" />
        <div style="height: 10px"></div>
        <q-btn class="full-width" unelevated size="16px" color="white" text-color="black"
          icon-right="iconfont icon-jiantou1" :label="$t('footer_menu.subscribed')" @click="subscribe" />
        <div class="etxt">
          <div><span class="iconfont icon-xin"></span>info@annilux.com</div>
          <div>
            <span class="iconfont icon-shijian"></span>{{ $t("footer_menu.hours24") }}
          </div>
        </div>
      </div>
      <div class="bitem ccon">
        <div>
          <h5>ANNILUX</h5>
          <p @click="contactShow = true">{{ $t("footer_menu.about_us") }}</p>
          <p @click="policyShow = true">
            {{ $t("footer_menu.service_support") }}
          </p>
        </div>
      </div>
      <div class="bitem lline">
        <h5>{{ $t("footer_menu.classify") }}</h5>
        <p v-for="(item, index) in navtxt" :key="index" @click="golink(item)">
          {{ item.title }}
        </p>
      </div>
      <div class="bitem lline">
        <h5>{{ $t("footer_menu.users") }}</h5>
        <template v-if="islogin">
          <router-link v-for="(item, index) in navarr" :key="index" :to="item.url">{{ item.name }}</router-link>
        </template>
        <template v-else>
          <router-link to="/login">{{ $t("login_mod.login_t") }}</router-link>
          <router-link to="/register">{{ $t("login_mod.reg_t") }}</router-link>
        </template>
      </div>
      <div class="bitem lline">
        <img class="wx-qrcode" src="img/WechatIMG1168.png" alt="">
      </div>
    </div>
    <div class="blackBottom" @click="backTop()">
      &copy; 2015 - 2021 ANNILUX - VAT Number: GB 313 749 012 - 1st Floor, 239
      Kensington High Street, London, United Kingdom, W8 6SN
    </div>
    <q-dialog v-model="contactShow">
      <q-card dark>
        <q-card-section>
          <div class="text-h6">{{ $t("footer_menu.about_us") }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <p>
            We're available Monday to Friday 8am-11pm GMT and Saturday to Sunday
            9am-9pm GMT.
          </p>
          <p>
            Email:
            <span class="blod">info@annilux.com</span>
          </p>
          <p>We'll be in touch soon</p>
          <p>
            We aim to respond within 24 hours, however, during busy periods like
            holidays, sales and special campaigns we may take a little longer
            getting back to you. Want to get in touch with our teams? Scroll
            down for more details.
          </p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" text-color="white" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="policyShow">
      <q-card dark>
        <q-card-section>
          <div class="text-h6">{{ $t("footer_menu.service_support") }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <p>Our Cookie Policy</p>
          <p>
            This website uses cookies, small files that are sent to your browser
            and stored on your device when you visit a website. Cookies allow
            efficient operation of the website, by optimizing the browsing
            experience. They also provide information to the owner of the
            website for statistical purposes or advertising, mainly to
            personalize the user experience by remembering their preferences.
          </p>
          <p>
            By consenting to the Privacy Policy, the User consents to the use of
            cookies by the website.
          </p>
          <p>
            Disclosure: We are a participant of Luisaviaroma.com's affiliate
            program.
          </p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" text-color="white" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-scroll-observer @scroll="onScroll" />
    <div class="backtop" :class="top > 1000 ? 'show' : 'hide'">
      <div class="cont" @click="backTop()">
        <span class="iconfont">&#xe609;</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mailbox } from '@/api/common'
import { emailRule } from '@/common/formRules'
import store from '@/store'
import _ from 'lodash'

export default {
  data() {
    return {
      navarr: _.cloneDeep(store.state.navList),
      email: '',
      contactShow: false,
      policyShow: false,
      isBreak: false,
      speed: 0,
      top: 0,
      navtxt: [
        { id: '1', title: this.$t('common.men') },
        { id: '2', title: this.$t('common.women') },
        { id: '3', title: this.$t('common.boy') },
        { id: '4', title: this.$t('common.girl') }
      ]
    }
  },
  computed: {
    islogin() {
      return this.$store.state.isLogin
    },
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  watch: {
    userInfo: {
      handler() {
        this.navarr = _.cloneDeep(store.state.navList)
        if (this.userInfo.usertype === '1') {
          this.navarr.push({
            name: this.$t('navList.sale'),
            url: '/my/salesRecord'
          })
          this.navarr.push({
            name: this.$t('navList.invite'),
            url: '/my/inviteRecord'
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    async subscribe() {
      if (this.email === '') {
        this.$q.notify(this.$t('footer_menu.enter_email'))
        return
      } else if (!emailRule(this.email)) {
        this.$q.notify(this.$t('footer_menu.email_err'))
        return
      }
      const res = await mailbox({
        mailbox: this.email
      })
      if (res.status === 1) {
        this.$q.notify(this.$t('footer_menu.subscription_succeeded'))
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
    },
    golink(item) {
      window.scrollTo(0, 0)
      const params = _.cloneDeep(store.state.goodsModule.originalParams)
      params.htype = item.id
      if (this.$route.path === '/goodslist') {
        store.commit('updataHandle', params)
      } else {
        const query = JSON.stringify(params)
        this.$router.push({
          path: '/goodslist',
          query: {
            params: encodeURI(query)
          }
        })
      }
      const obj = {
        id: item.id,
        title: item.title
      }
      setTimeout(() => {
        this.$store.commit('setPageType', obj)
      }, 100)
    },
    onScroll(e) {
      this.speed = Math.floor(-e.position.top / 10)
      if (this.top < e.position.top) {
        this.isBreak = true
      } else {
        this.isBreak = false
      }
      this.top = e.position.top
    },
    backTop() {
      let timer = null
      clearInterval(timer)
      this.isBreak = false
      timer = setInterval(() => {
        window.scrollTo(0, this.top + this.speed)
        if (this.top === 0 || this.isBreak) {
          clearInterval(timer)
        }
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 60px;
  color: #fff;
  background-color: #000;
}

.footerCon {
  max-width: 1500px;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  .email {
    width: 450px;
    margin-left: 10px;

    .etxt {
      margin-top: 10px;
      height: 35px;
      padding: 5px 0;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      display: flex;

      div {
        display: inline-block;
        width: 50%;
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;

        span {
          margin-right: 5px;
        }
      }

      &>div:nth-of-type(1) {
        border-right: 1px solid #fff;
      }
    }

    :deep(.q-field__native, .q-field__input) {
      font-size: 16px;
      text-align: center;
    }

    :deep(.q-icon) {
      position: absolute;
      font-size: 16px;
      right: 10px;
    }
  }

  .bitem {
    flex: 2;
    text-align: left;
    padding-left: 10px;

    div {
      display: inline-block;
    }

    h5 {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      text-align: left;
    }

    p {
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 0;
      cursor: pointer;
      width: fit-content;

      &:hover {
        text-decoration: underline;
      }
    }

    a {
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 0;
      cursor: pointer;
      width: fit-content;
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .lline {
    flex: 1;
  }

  .ccon {
    text-align: center;
  }

  .wx-qrcode{
    width: 150px;
    height: 150px;
    margin-right: 15px;
  }
}

.blackBottom {
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.backtop {
  position: fixed;
  right: 30px;
  bottom: 50px;
  z-index: 99;
  transition: opacity 0.4s;
  cursor: pointer;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  .cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #000;
    border-radius: 2px;
    color: #fff;

    .iconfont {
      font-size: 12px;
      transform: rotate(-90deg);
    }
  }
}
</style>
